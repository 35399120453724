body {
  margin: 0;
  height: 100vh;
  /* overflow: hidden; */
  background-color: #1B2025;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
  width: 15px;
  margin:15px;
}
 
::-webkit-scrollbar-track {
  /* background-color: #1B2025; */
}
 
::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;

}

 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100%;
}